import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import JudgeTime from '../utils';
import RelationFineGroup, { BudgetSubjectTable } from '../components';

formCreate.component('RelationFine', RelationFineGroup);
formCreate.component('BudgetSubjectForm', BudgetSubjectTable);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      isControl: null,
      formFunctionCode: 'tpm_activity_project_form',
      formParentCode: 'CRM20210918000002712',
      actType: 'project',
      rule: [],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          },
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          },
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          },
        }],
      },
      fData: {},
      buttonText: {
        submit: '保存',
      },
      buttons: {
        submitAudit: this.formConfig.code !== 'view',
      },
      BudgetSubjectsList: [],
      budgetEntitySelectionList: [],
    };
  },

  async created() {
    if (this.formConfig.code === 'view' || this.formConfig.code === 'edit') {
      await this.getBBudgetSubjectsList();
      await this.getBudgetEntitySelection();
      await this.init();
    }
    this.isControl = await JudgeTime.getControlType();
    this.getControlConfig();
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'actTime') { // 活动时间
        v.validate = {
          message: '活动时间不能为空',
          required: true,
          trigger: 'change',
        };
        v.props = {
          ...v.props,
          type: 'datetimerange',
          pickerOptions: this.pickerOptions,
          rangeSeparator: '至',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          unlinkPanels: true,
          align: 'right',

          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          defaultTime: ['00:00:00', '23:59:59'],
        };
        v.on = {
          change: this.onActTimeChange,
        };
      }

      if (v.field === 'budget') {
        v.props = {
          ...v.props,
          saveRef: this.saveBudgetRef,
          startTime: '',
          endTime: '',
          isControl: this.isControl !== 'no_control',
        };
        v.value = {
          data: [],
        };
        v.on = {
          ...v.on,
          change: this.onBudgetCode,
          clear: this.clearBudgetCode,
          deleteAssociate: this.deleteAssociate,
        };
      }

      if (v.field === 'relationFine') {
        v.props = {
          ...v.props,
          time: {},
          controlIds: [],
          saveRef: this.saveRef,
          code: this.formConfig.code,
        };
        v.validate = [
          {
            trigger: 'change',
            required: true,
            message: '请填写明细信息！',
          },
        ];
      }
      return v;
    },

    // 获取管控配置
    getControlConfig() {
      if (!this.judgeTime) {
        this.judgeTime = new JudgeTime(this.formConfig.code !== 'add' ? {
          time: [
            `${this.formConfig.row.beginDate} ${this.formConfig.row.beginDateSecond}`,
            `${this.formConfig.row.endDate} ${this.formConfig.row.endDateSecond}`,
          ],
          model: this.getFormData(),
        } : null);
      }
    },

    saveBudgetRef(ref) {
      this.budgetRef = ref;
    },

    saveRef(ref) {
      this.relationFineRef = ref;
    },

    // 获取大类数据
    async getSelectOptions(url, params) {
      const res = await request.post(url, params);
      return res.result.data || res.result || [];
    },

    // 获取活动详情
    async getBudgetValue(val) {
      const relationFine = this.getRule('relationFine');
      relationFine.value = {
        data: val,
      };
    },

    // 删除关联信息
    async deleteAssociate(row) {
      const relationFine = this.getRule('relationFine');
      if (row) {
        const res = await request.post('/tpm/tpmActController/getCategoriesByBudget', {
          enableStatus: '009',
          controlIds: [row.id],
          actType: 'project',
        });
        const detailData = relationFine.value;
        if (res.result.length > 0) {
          // 判断是否有关联的活动大类，有，删除关联的活动大类信息
          res.result.forEach((item, k) => {
            detailData.forEach((dd, j) => {
              if (item.categoriesCode === dd.categoriesCode) {
                detailData.splice(j, 1);
              }
            });
          });
        } else {
          detailData.forEach((detail, b) => {
            if (row.feeBudgetCodes === detail.ext61) {
              detailData[k].activityInfo[j].splice(b, 1);
            }
          });
        }
        relationFine.value = detailData;
      }
    },

    // 监听大类
    onCategoriesCode(val, data) {
      let categoriesName = '';
      const index = data.findIndex((item) => item.value === val);
      if (index > -1) {
        categoriesName = data[index].label;
      }
      if (val) {
        const relationFine = this.getRule('relationFine');
        relationFine.props = {
          ...relationFine.props,
          categoriesCode: val,
          categoriesName,
        };
      }
    },

    // 监听预算科目
    onBudgetCode(row) {
      let ids = [];
      let productLevelName = '';
      let showOrg = '';
      if (row && row.length) {
        ids = row.map((item) => item.id);
        if (row[0].productLevelName) {
          productLevelName = row[0].productLevelName;
        }
        if (row[0].ext40) {
          showOrg = row[0].ext40;
        }
      }
      const relationFine = this.getRule('relationFine');
      relationFine.props = {
        ...relationFine.props,
        controlIds: ids,
        budgetList: row,
        productLevelName,
        showOrg,
      };

      if (this.relationFineRef) {
        this.relationFineRef.reset(ids);
      }
    },

    // 监听清空预算科目
    clearBudgetCode() {
      const relationFine = this.getRule('relationFine');
      relationFine.props = {
        ...relationFine.props,
        controlIds: [],
        budgetList: [],
      };

      if (this.relationFineRef) {
        this.relationFineRef.clear();
      }
    },

    // 时间格式化
    getTime(startTime, endTime) {
      return {
        startTime,
        endTime,
        beginDate: startTime.indexOf(' ') ? startTime.split(' ')[0] : '',
        beginDateSecond: startTime.indexOf(' ') ? startTime.split(' ')[1] : '',
        endDate: endTime.indexOf(' ') ? endTime.split(' ')[0] : '',
        endDateSecond: endTime.indexOf(' ') ? endTime.split(' ')[1] : '',
      };
    },

    // 格式化
    formateTime(val) {
      let time = null;
      if (val && Array.isArray(val) && val.length === 2) {
        const {
          beginDate, beginDateSecond, endDate, endDateSecond,
        } = this.getTime(val[0], val[1]);
        time = {
          beginDate, beginDateSecond, endDate, endDateSecond,
        };
      }
      return time;
    },

    // 改变活动时间
    onActTimeChange(val) {
      if (this.relationFineRef) {
        this.relationFineRef.clear();
      }
      if (this.budgetRef) {
        this.budgetRef.deleteTable();
      }
      if (val && Array.isArray(val) && val.length === 2) {
        const startTime = val[0];
        const endTime = val[1];
        const budgetCode = this.getRule('budget');
        budgetCode.props = {
          ...budgetCode.props,
          startTime,
          endTime,
        };

        // 时间发生变化时，比较差异。删除不在活动时间内的预算
        if (this.budgetRef) {
          this.budgetRef.reset(startTime, endTime);
        }

        const relationFine = this.getRule('relationFine');
        relationFine.props = {
          ...relationFine.props,
          time: this.getTime(startTime, endTime),
        };
      } else {
        const budgetCode = this.getRule('budget');
        budgetCode.props = {
          ...budgetCode.props,
          startTime: '',
          endTime: '',
        };
      }
    },
    // 获取可以选择公司主体的预算科目
    getBudgetEntitySelection() {
      request.post(
        '/mdm/mdmdictdata/list',
        {
          dictTypeCode: 'budget_entity_selection',
        },
      ).then((res) => {
        if (res.success) {
          this.budgetEntitySelectionList = res.result;
        }
      });
    },
    // 获取b类预算科目
    getBBudgetSubjectsList() {
      request
        .post('/mdm/mdmdictdata/list', {
          dictTypeCode: 'B_budget_subjects',
        })
        .then((res) => {
          if (res.success) {
            this.BudgetSubjectsList = res.result;
          }
        });
    },

    // 处理明细信息
    formateDetail(data) {
      const detailVos = [];
      data.forEach((item) => {
        item.activityInfo.forEach((act) => {
          let startDate = ''; let startSecond = ''; let endDate = ''; let endSecond = '';
          if (act.executeTime && act.executeTime.length > 0) {
            const [tempStart, tempEnd] = act.executeTime;
            [startDate, startSecond] = tempStart.split(' ');
            [endDate, endSecond] = tempEnd.split(' ');
          }
          detailVos.push({
            executeBeginDate: startDate,
            executeBeginDateSecond: startSecond,
            executeEndDate: endDate,
            executeEndDateSecond: endSecond,
            categoriesName: item.categoriesName,
            categoriesCode: item.categoriesCode,
            fineCode: item.fineCode,
            fineName: item.fineName,
            ext71: item.ext71, // 是否推送sfa
            ext72: item.ext72, // 是否需要督查
            ext63: item.ext63,
            ext73: item.ext73,
            ext68: item.ext68, // 表单号
            ...act,
            isReturn: item.isReturn,
            productTypeName: item.productTypeName,
            productType: item.productType,
            tastingTypeName: item.tastingTypeName,
            tastingTypeCode: item.tastingTypeCode,
          });
        });
      });
      console.log(detailVos);
      return detailVos;
    },

    // 获取明细map
    getDetail(data) {
      const { detailVos } = data;
      const detail = [];
      if (detailVos && detailVos.length) {
        detailVos.forEach(async (subItem, index) => {
          const item = subItem;
          // let BudgetSubjectsList = null;
          // BudgetSubjectsList = this.BudgetSubjectsList.find((v) => v.dictCode === item.budgetSubjectsCode);
          // console.log(item.budgetSubjectsCode, this.BudgetSubjectsList);
          // if (BudgetSubjectsList) {
          //   if (this.formConfig.code === 'edit') {
          //     item.payTypes = [{
          //       dictValue: '5',
          //       dictKey: 'B类费用池',
          //     }];
          //     item.payType = '5';
          //   }
          // }
          // if (this.formConfig.code === 'edit') {
          //   if (item.ext2 === '00201') {
          //     item.payTypes = [{
          //       dictValue: '5',
          //       dictKey: 'B类费用池',
          //     }];
          //     item.payType = '5';
          //   } else {
          //     item.payTypes = [{
          //       dictValue: '1',
          //       dictKey: '现金',
          //     }, {
          //       dictValue: '4',
          //       dictKey: '货补（产品）',
          //     }];
          //   }
          // }
          if (item.isReturn === 'Y') {
            item.isReturnName = '是';
          } else if (item.isReturn === 'N') {
            item.isReturnName = '否';
          } else {
            item.isReturnName = '';
          }
          if (this.formConfig.code === 'edit') {
            if (item.isOpen === '1') {
              // const dataObj = await request.get('/tpm/tpmTastingPrice/findTastingPrice', { productCodeGift: item.tastingWineCode });
              const dataObj = await request.post('/mdm/mdmPriceSettingExtController/searchAllPrice', {
                conditionTypeEnum: 'A002',
                priceSettingType: '04',
                priceTypeEnum: 'P002',
                companyCode: item.ext2,
                productCode: item.tastingWineCode,
              });
              if (dataObj.code === 200) {
                item.productAmount = (Number(((dataObj.result || {}).itemResultVoList[0] || {}).price) || 0) * (item.tastingNumber || 0);
                item.price = ((dataObj.result || {}).itemResultVoList[0] || {}).price || 0;
              } else {
                this.$message.error('品鉴用酒没有找到对应的价格维护');
                item.productAmount = 0 * (item.tastingNumber || 0);
                item.price = 0;
              }
            }
          }
          if (this.budgetEntitySelectionList.find((v) => v.dictCode === item.budgetSubjectsCode)) {
            item.budgetAccount = true;
          } else {
            item.budgetAccount = false;
          }
          item.disableExt7 = false;
          data.editBudgetVoList.forEach((v) => {
            if (v.feeBudgetCode === item.ext61 && v.customerCode) {
              item.disableExt7 = true;
            }
          });
          if (detail.length === 0) {
            detail.push({
              categoriesName: item.categoriesName,
              categoriesCode: item.categoriesCode,
              fineCode: item.fineCode,
              fineName: item.fineName,
              productTypeName: item.productTypeName,
              productType: item.productType,
              tastingTypeName: item.tastingTypeName,
              tastingTypeCode: item.tastingTypeCode,
              payTypes: item.payTypes || [],
              ext71: item.ext71, // 是否推送sfa
              ext72: item.ext72, // 是否需要督查
              ext63: item.ext63,
              ext73: item.ext73,
              isReturn: item.isReturn,
              isReturnName: item.isReturnName,
              budgetAccount: item.budgetAccount,
              disableExt7: item.disableExt7,
              activityInfo: [{
                productData: this.setChooseProduct(item),
                ...item,
                tabValue: `activity${index}-1`,
                payTypes: item.payTypes || [],
                executeTime: item.executeEndDateSecond ? [`${item.executeBeginDate} ${item.executeBeginDateSecond}`, `${item.executeEndDate} ${item.executeEndDateSecond}`] : [],
              }],
              ext68: item.ext68, // 表单号
              editableTabsValue: `activity${index}-1`, // 激活的选项
            });
          } else {
            let flag = false;
            detail.forEach((act, aIndex) => {
              if (act.categoriesCode === item.categoriesCode && act.fineCode === item.fineCode) {
                flag = true;
                detail[aIndex].activityInfo.push({
                  ...subItem,
                  productData: this.setChooseProduct(act),
                  tabValue: `activity${index}-${aIndex + 1}`,
                  payTypes: item.payTypes,
                  executeTime: item.executeEndDateSecond ? [`${item.executeBeginDate} ${item.executeBeginDateSecond}`, `${item.executeEndDate} ${item.executeEndDateSecond}`] : [],
                });
              }
            });
            if (!flag) {
              detail.push({
                categoriesName: item.categoriesName,
                categoriesCode: item.categoriesCode,
                fineCode: item.fineCode,
                fineName: item.fineName,
                productTypeName: item.productTypeName,
                productType: item.productType,
                tastingTypeName: item.tastingTypeName,
                tastingTypeCode: item.tastingTypeCode,
                payTypes: item.payTypes || [],
                ext71: item.ext71, // 是否推送sfa
                ext72: item.ext72, // 是否需要督查
                ext63: item.ext63,
                ext73: item.ext73,
                isReturn: item.isReturn,
                isReturnName: item.isReturnName,
                budgetAccount: item.budgetAccount,
                activityInfo: [{
                  ...item,
                  productData: this.setChooseProduct(item),
                  tabValue: `activity${index}-1`,
                  payTypes: item.payTypes || [],
                  executeTime: item.executeEndDateSecond ? [`${item.executeBeginDate} ${item.executeBeginDateSecond}`, `${item.executeEndDate} ${item.executeEndDateSecond}`] : [],
                }],
                ext68: item.ext68, // 表单号
                editableTabsValue: `activity${index}-1`, // 激活的选项
              });
            }
          }
        });
      }
      return detail;
    },
    // 设置选中的产品信息
    setChooseProduct(data) {
      const tempData = [];
      if (data.ext31) {
        const productCode = data.ext31.split(',') || [];
        const productName = data.ext32.split(',') || [];
        const levelCode = data.ext33.split(',') || [];
        const levelName = data.ext34.split(',') || [];

        productCode.forEach((item, index) => {
          tempData.push({
            productLevelName: levelName[index] || '',
            productLevelCode: levelCode[index] || '',
            materialName: productName[index],
            materialCode: item,
          });
        });
        return tempData;
      }
    },

    // 根据费用预算类型-设置reduceOrder
    formateBudget(data) {
      const budget = new Map();
      const tmp = [];
      if (data && data.length) {
        data.forEach((item) => {
          const ids = item.feeBudgetType + item.orgType;
          if (budget.has(ids)) {
            const values = budget.get(ids);
            values.push(item);
            budget.set(ids, values);
          } else {
            budget.set(ids, [item]);
          }
        });

        if (budget.size) {
          let index = 0;
          budget.forEach((value) => {
            index += 1;
            value.forEach((feeBudget) => {
              const obj = feeBudget;
              obj.feeBudgetVos[0].reduceOrder = index;
              tmp.push({
                ...obj,
              });
            });
          });
        }
      }
      return tmp;
    },

    // 提交
    async submit(type) {
      let params = this.getFormData();
      if (!params) return false;

      let url = '/tpm/tpmActController/save';

      const { row, code } = this.formConfig;
      const { actTime, budget, relationFine } = params;

      if (!actTime) return false;

      if (budget.data && budget.data.length === 0) {
        this.$message({
          type: 'error',
          message: '请选择预算!',
        });
        return false;
      }

      if (relationFine.data && relationFine.data.length === 0) {
        this.$message({
          type: 'error',
          message: '请填写活动明细',
        });
        return false;
      }
      if (relationFine && relationFine.length > 0) {
        let isTrue = true;
        let isAct = true;
        let isExt3 = true;
        relationFine.forEach((v) => {
          if (!v.productType && !v.productTypeName) {
            isTrue = false;
          }
          if (v.categoriesCode === 'TRLX0042' && !v.tastingTypeName && !v.tastingTypeCode) {
            isAct = false;
          }
          v.activityInfo.forEach((v1) => {
            if (!v1.ext3 || !v1.ext2) {
              isExt3 = false;
            }
          });
          console.log(v);
        });
        if (!isTrue) {
          this.$message({
            type: 'error',
            message: '请选择活动明细产品系列字段',
          });
          return false;
        }
        if (!isAct) {
          this.$message({
            type: 'error',
            message: '请选择活动明细活动类型字段',
          });
          return false;
        }
        if (!isExt3) {
          this.$message({
            type: 'error',
            message: '请选择活动明细公司主体字段',
          });
          return false;
        }
      }
      params.actType = this.actType;
      // 时间处理
      const time = this.formateTime(actTime);
      if (time) {
        params = { ...params, ...time };
      }

      params.budgetVos = budget.data || [];
      // 处理明细
      params.detailVos = this.formateDetail(relationFine);
      // 文件处理
      params.ext11 = JSON.stringify(params.ext11 || []);

      delete params.budget;
      delete params.relationFine;
      delete params.actTime;
      if (code === 'edit') {
        url = '/tpm/tpmActController/update';
        params = { ...params, id: row.actId || row.id, actCode: row.actCode };
        if (type === 1) {
          params.saveType = 3;
        } else {
          params.saveType = 4;
        }
      } else {
        params.saveType = type;
      }
      params.ext38 = 'N';
      // 跳转下一步
      if (type === 2) {
        this.$emit('submit', {
          row: params,
          submitUrl: url,
        });
      } else {
        const { success } = await request.post(url, params);
        if (success) {
          this.$message({
            type: 'success',
            message: '保存成功',
          });
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      }
    },

    async init() {
      const { row } = this.formConfig;
      console.log(this.formConfig.row, 999);
      const data = await request.get('/tpm/tpmActController/query', { id: this.formConfig.row.actId || this.formConfig.row.id || '', actCode: row.actCode || '' }).then((res) => (res.success ? res.result : []));
      // 处理时间
      data.actTime = [`${data.beginDate} ${data.beginDateSecond}`, `${data.endDate} ${data.endDateSecond}`];
      // 费用预算编码
      data.budget = {
        data: data.editBudgetVoList || [],
      };
      const budgetCode = this.getRule('budget');
      budgetCode.props = {
        ...budgetCode.props,
        startTime: data.beginDate,
        endTime: data.endDate,
      };
      const relationFine = this.getRule('relationFine');
      relationFine.props = {
        code: this.formConfig.code,
        ...relationFine.props,
        time: this.getTime(data.beginDate, data.endDate),
        controlIds: data.editBudgetVoList && data.editBudgetVoList.length ? data.editBudgetVoList.map((item) => item.id) : [],
        budgetList: data.editBudgetVoList || [],
        showOrg: data.editBudgetVoList && data.editBudgetVoList.length ? data.editBudgetVoList[0].ext40 : '',
      };
      data.relationFine = this.getDetail(data);
      if (data.ext11) {
        data.ext11 = JSON.parse(data.ext11) || [];
      } else {
        data.ext11 = [];
      }
      this.fData = data;
      this.setValue(data);
    },
  },
};
